<template>
    <PageLayout>
        <template>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center flex-grow-1">
                    <h1>
                        <template>
                            {{ $t('deals') }}
                        </template>
                    </h1>
                </div>
            </div>

            <div class="mobile-filter mb-5" v-if="$vuetify.breakpoint.smAndDown">
                <v-icon
                    class="mobile-filter__btn mr-2"
                    color="secondary"
                    @click="filter_show === true ? filter_show = false : filter_show = true"
                    :title="$t['filter_add']"
                >mdi-filter-plus</v-icon>

                <FilterMobileLinks
                    :links="[

                        ...deal_statusItems.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),
                    ]"
                />
            </div>

            <FilterComponent
                class="mb-10"
                v-if="filter_show"
                :filterData="filterData"
                :currentFilter="currentFilter"
                :value="filterValues"
                :loading="loading"
                :invalid="invalid"
                entity="document"
                @input="filterValues = $event"
                @submit="submitForm"
                @filterSaved="filterSaved"
                @filterDeleted="filterDeleted"
            />

            <template v-if="$vuetify.breakpoint.smAndDown">
                <ListItem
                    v-for="item in deals"
                    :item="item"
                    :key="item.id"
                    :columns="tableColumns"
                >
                    <template v-slot:number="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            <v-hover v-slot="{ hover }">
                                <div>
                                    <v-btn  text plain :to="'/deal/' + item.uuid " class="cursor-pointer font_weight_600 button_link">
                                        {{ item.number }}
                                    </v-btn>
                                </div>
                            </v-hover>
                        </div>
                    </template>
                    <template v-slot:deal_status="{ item }">
                        <div :style="'color:'+ item.color+'!important'">{{ item.deal_status }}</div>
                    </template>
                    <template v-slot:created_at="{ item }">
                        <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                    </template>
                    <template v-slot:admin_id="{ item }">
                        <User item="item.admin" />
                    </template>

                </ListItem>
            </template>
            <template v-else>
                <v-col cols="12" class="px-0 mx-0" >

                    <v-list two-line class="background_color_transparent" >
                        <v-list-item-group
                            v-model="selected"
                            class="my-0 "

                        >

                            <v-list-item
                                v-for="(item, i) in deals"
                                :key="i"
                                :class="'rounded-sm  topic_background ' + ($vuetify.breakpoint.xsOnly ? 'pl-15' : 'px-3')"
                                class="mb-5 height_list_item"
                                :style="'border-color:' + item.color +' !important' "
                                @click="handleClick(item)"
                            >

                                <template v-slot:default="{ active }">




                                    <v-list-item-content class="ml-6 topic_padding_top0">

                                        <v-row class="topic_margin_top0">
                                            <v-col cols="12"  md="3" sm="4" lg="2"  >
                                                <v-list-item-title  v-text="$t('deal_number')"></v-list-item-title>
                                                <v-list-item-subtitle class="topic_title">{{item.number}}</v-list-item-subtitle>
                                            </v-col>
                                            <v-col cols="12"  md="3" sm="3" lg="2" >
                                                <v-list-item-title class="topic_text">{{$t('deal_status')}}</v-list-item-title>
                                                <v-list-item-subtitle class="topic_title">{{item.deal_status}}</v-list-item-subtitle>
                                            </v-col>
                                            <v-col cols="12"  md="6" sm="5" lg="6" >
                                                <v-list-item-title class="topic_text">{{$t('order_number')}}</v-list-item-title>
                                                <v-list-item-subtitle class="topic_title">{{item.order_number}}</v-list-item-subtitle>
                                            </v-col>
                                            <v-col cols="12"  md="4" sm="4" lg="2" class="padding_top10_bottom0">
                                                <v-list-item-title class="topic_text">{{$t('date')}}</v-list-item-title>
                                                <v-list-item-subtitle class="topic_title">{{ $moment(item.created_at).format('DD.MM.YYYY') }}</v-list-item-subtitle>
                                            </v-col>

                                        </v-row>


                                    </v-list-item-content>

                                </template>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>


                </v-col>
            </template>
            <div class="main-pagination d-flex flex-row justify-end">
                <SelectInput
                    class="main-pagination__per-page"
                    id="pagination-variants"
                    :value="options.itemsPerPage"
                    :items="perPageItems"
                    :label="$t('items_per_page')"
                    :disabled="loading"
                    @input="onPerPageChange($event)"
                    hide-label
                ></SelectInput>
                <v-pagination v-model="page" :total-visible="totalVisiblePag" class="main-pagination__pagination pagination_table" :length="pageCount" :disabled="loading" @input="onPageChange"></v-pagination>
            </div>
        </template>

        <template v-slot:side>
            <v-icon v-if="$vuetify.breakpoint.mdAndUp" class="mt-2 mb-5"  color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true" :title="$t['filter_add']">mdi-filter-plus</v-icon>

            <v-list  class="filter_left" v-if="deal_statusItems.length > 0 && $vuetify.breakpoint.mdAndUp">
                <v-list-group :ripple="false" v-model="group1" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('status') }}</v-list-item-title>
                    </template>


                    <v-list-item :ripple="false" v-for="(item, i) in deal_statusItems" :key="i" :to="item.url" link >
                        <v-list-item-title v-text="item.name" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon v-text="'-'" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </template>
    </PageLayout>
</template>
<script>

    import {mapGetters} from "vuex"
    import FilterView from "@/plugins/mixins/FilterView";
    import ResourceView from "@/plugins/mixins/ResourceView";
    import NavMixin from "@/plugins/mixins/Nav";

    import FilterComponent from "@/components/FilterComponent.vue";
    import PageLayout from "@/components/Leentech/PageLayout.vue";
    import FilterMobileLinks from "@/components/FilterMobileLinks.vue";
    import ListItem from "@/components/Deal/ListItem.vue";
    import SelectInput from "@/components/Form/SelectInput.vue";


    export default {
        name: "Deals",
        mixins: [
            FilterView,
            ResourceView,
            NavMixin
        ],
        components: {
            FilterMobileLinks,
            FilterComponent,
            PageLayout,
            ListItem,
            SelectInput
        },
        data() {
            return {
                filter_show: false,
                entityName: 'deal_for_client',
                options: {
                    ...FilterView.data().options,
                    sortBy: ['id']
                },
                progress:0,
                progress2:0,
                progress_deal:false,
                search:false,
                nextLink: true,
                color: 'primary',

                date: null,
                dateDialog: false,
                fab: false,
                deal: null,
                deals: [],
                sortBy: "created_at",
                sortDir: false,
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalDeals: 0,
                loading: false,
                headers: [
                    {
                        text: this.$t('deal_number'),
                        align: "start",
                        sortable: false,
                        value: "number"
                    },
                    {
                        text: this.$t('deal_status'),
                        align: "start",
                        sortable: false,
                        value: "deal_status"
                    },
                    {
                        text: this.$t('order_number'),
                        align: "start",
                        sortable: false,
                        value: "order_number"
                    },
                    {
                        text: this.$t('date'),
                        align: "start",
                        sortable: false,
                        value: "date"
                    },
                ],





                deal_uuid: null,
                no_data: false,
                totalVisiblePag: 7,
                deal_statusItems: [],
                group1:true,
                selected: [],


            };
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
            filteredHeaders() {
                return this.headers.filter(h => !h.hide)
            },
        },
        mounted() {

            this.options.itemsPerPage = this.perPage = this.itemsPerPage
            this.init();
        },


        methods: {
            async init() {
                this.getFilters()
                await  this.getDeals()
                await  this.getDealStatuses(3)
            },

            handleClick(item) {
                this.$router.push({
                    name: 'deal.show',
                    params: {
                        uuid: item.uuid
                    }
                })

            },

            rowClass(){
                //  const rowClass = (this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly) ? '' : 'rowClass'
                //return 'rowClass rowDeal';
                return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass2 rowDeal' : 'rowMobiClass';
            },
            openSearch(){
                if(this.search === true){
                    this.search = false
                    this.color = 'primary';
                }
                else{
                    this.search = true
                    this.color = '';
                }
            },

            async getDeals() {
                var _this = this;
                this.loading = true;
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {};
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc';
                }
                if (page !== undefined) {
                    params.page = page

                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }


                if (this.filterValues) {
                    params.fltr = this.filterValues;
                }

                await this.$http
                    .get("client/deal", {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.filterData = res.body.filter;
                        this.deals = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalDeals = res.body.meta.total
                        this.pageCount = res.body.meta.last_page

                    })
                    .catch(err => {
                        this.deals = []
                        this.totalDeals = 0
                        this.$toastr.error(this.$t('failed_to_get_list_deals'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },


            async getDealStatuses(business_process) {
                let params = {};
                params.filter = 'all';
                params.business_process = business_process;
                await this.$http
                    .get(`client/deal_status`, {
                        params: params,
                    })
                    .then(res => {
                        this.deal_statusItems = res.body.data.map(item => {
                            return {
                                id: item.id,
                                name: item.client_name,
                                url: {
                                    name: 'deal',
                                    params: {
                                        id: this.business_process
                                    },
                                    query: {
                                        filter: {
                                            deal_status_id: {
                                                eq: item.id
                                            }
                                        }
                                    }
                                }
                            }
                        })
                    })
                    .catch(err => {
                        this.deal_statusItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_deal_statuses'))
                    });
            },





        }
    }
</script>

<style scoped>
.rowClass2{
    min-height: 63px;
    background-color: #F5F5F5 !important;
    display: block;
    width: 100%;
    margin: 0 0 20px 0;
    border-radius: 10px;

}
.rowClass2 td{
    margin: 0 !important;
    width: 20%;
    display: inline-block;
    border-bottom: 0 !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    letter-spacing: 0 !important;
    text-align: left !important;
    color: #333333 !important;
}

.rowClass2 td .title{
    font-size: 11px !important;
    font-weight: 400 !important;
    line-height: 14px!important;
    letter-spacing: 0 !important;
    text-align: left !important;
    color: #B3B3B3 !important;
    margin: 14px 0 3px 0 !important;
    font-family: "PT Sans Caption", system, -apple-system, 'Arial', sans-serif !important;

}

.rowClass2 td .text{
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    color: #333333;
}
</style>



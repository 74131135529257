
<script>
import User from "@/components/User.vue";

export default {
    name: "DealListItem",
    components: {User},

    props: {
        item: {
            type: Object,
            required: true
        },

        columns: {
            type: Array,
            required: true
        },
    },

    computed: {
        activeColumns() {
            return this.columns.filter(column => {
                return column.active
                    && column.prop !== 'id'
                    && column.prop !== 'admin'
                    && column.prop !== 'name'
                    && column.prop !== 'deal_status_id'
            });
        }
    },

    methods: {
        deleteItem(item) {
            this.$emit('deleteItem', item);
        }
    }
}
</script>

<template>
    <div class="item">

        <div class="item__header">
            <div class="item__status small-text" :style="{ backgroundColor: item.color }">{{ item.deal_status }}</div>
            <div class="item__created_at text-color-light small-text">{{ item.created_at }}</div>
        </div>

        <div class="item__description">
            <div class="item__id text-color-light small-text">#{{ item.id }}</div>
            <div class="item__name">
                <router-link  text plain :to="'/deal/' + item.uuid " class="cursor-pointer font_weight_500 button_link">
                    {{ item.name }}
                </router-link>
            </div>


        </div>



        <div class="item__data" >
            <div class="text-info" >
                <div class="text-info__label">
                    <div>{{$t('deal_number')}}</div>
                </div>
                <div class="text-info__value">
                    <v-btn  text plain :to="'/deal/' + item.uuid " class="cursor-pointer font_weight_600 button_link">
                        {{ item.number }}
                    </v-btn>
                </div>
            </div>
            <div class="text-info" >
                <div class="text-info__label">
                    <div>{{$t('date_created')}}</div>
                </div>
                <div class="text-info__value">
                    {{$moment(item.created_at).format('DD.MM.YYYY') }}
                </div>
            </div>
            <div class="text-info" >
                <div class="text-info__label">
                    <div>{{$t('order_number')}}</div>
                </div>
                <div class="text-info__value">
                    {{item.order_number}}
                </div>
            </div>
        </div>


        <div class="item__footer">
            <User :item="item.admin" />
        </div>
    </div>
</template>

